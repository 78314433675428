import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CoursesList from "../components/CoursesList";
import BestCoursesList from "../components/BestCoursesList";
import { coursesServices } from "../services/api/courses";
import Context from "../context/UserContext";
import { notifications } from "../utils/notifications";
import TableLoading from "../components/TableLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Courses() {
  const [isLoadingBestCourses, setIsLoadingBestCourses] = useState(true);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [courses, setCourses] = useState([]);
  const [coursesSerch, setCoursesSearch] = useState([]);
  const [bestCourses, setBestCourses] = useState([]);
  const { currentUser } = useContext(Context);

  const navigate = useNavigate();

  const searchFunction = (e) => {
    setCourses(filterItems(coursesSerch, e.target.value.toLowerCase()));
    if (e.target.value === "") return setCourses(coursesSerch);
  };

  const filterItems = (arr, query) => {
    return arr.filter((el) =>
      el.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  const dateFilter = (value) => {
    if (!coursesSerch) return;
    switch (value) {
      case "old":
        setCourses(
          [...coursesSerch].sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          )
        );
        break;
      case "new":
        setCourses(
          [...coursesSerch].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
        );
        break;
      default:
        setCourses(coursesSerch);
        break;
    }
    return courses;
  };

  const handleAddCourse = async (id) => {
    if (currentUser) {
      await coursesServices
        .addCourseMembership(id)
        .then((response) => {
          notifications.success(response.data.message);
          fetchCourses();
          fetchBestCourses();
        })
        .catch((err) => {});
    } else {
      /* eslint-disable jsx-a11y/anchor-is-valid */
      notifications.warn(
        <div>
          {" "}
          <a
            onClick={() => navigate({ pathname: "", search: "?login=true" })}
            className="text-primary-200"
          >
            Inicie sesión{" "}
          </a>
          para adquirir un curso
        </div>
      );
    }
  };
  const fetchBestCourses = () => {
    coursesServices
      .getBestCourses()
      .then((response) => {
        setBestCourses(response.data.courses);
      })
      .catch((err) => {})
      .finally(() => setIsLoadingBestCourses(false));
  };
  const fetchCourses = () => {
    coursesServices
      .getCourses()
      .then((response) => {
        setCourses(response.data.courses);
        setCoursesSearch(response.data.courses);
      })
      .catch((err) => {})
      .finally(() => setIsLoadingCourses(false));
  };

  useEffect(() => {
    fetchBestCourses();
    fetchCourses();
  }, [currentUser]);

  return (
    <div className=" md:px-0 mb-16 mx-6">
      <div className="border-b border-gray-300">
        <div className="container mx-auto h-20 lg:h-44 flex items-center justify-center md:justify-start  text-center lg:text-left">
          <h1 className="text-3xl lg:text-6xl font-bold text-gray-700">
            Cursos online
          </h1>
        </div>
      </div>
      {coursesSerch.length > 0 && (
        <div className="flex  w-full py-2 top-0">
          <div className="container mx-auto px-4 md:px-0 ">
            <div className="flex justify-items-start lg:space-x-8">
              <label className="relative block w-full lg:w-6/12">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FontAwesomeIcon
                    icon={["fa", "magnifying-glass"]}
                    className="text-[#A9A9A9]"
                    size="sm"
                  />
                </span>
                <input
                  className="w-full bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
                  type="text"
                  placeholder="Buscar cursos..."
                  onChange={searchFunction}
                  onKeyDown={searchFunction}
                />
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto pt-8">
        <div>
          {isLoadingBestCourses ? (
            <div>
              <h2 className="text-gray-700 font-bold text-xl lg:text-3xl mb-8 text-center lg:text-left">
                Los más populares
              </h2>
              <div className="w-full flex justify-center">
                <TableLoading size="5x" />
              </div>
            </div>
          ) : (
            bestCourses &&
            bestCourses.length > 0 && (
              <div>
                <h2 className="text-gray-700 font-bold text-xl lg:text-3xl mb-8 text-center lg:text-left">
                  Los más populares
                </h2>
                <BestCoursesList
                  bestCourses={bestCourses}
                  handleAddCourse={(id) => handleAddCourse(id)}
                />
              </div>
            )
          )}
        </div>

        <div className="flex flex-wrap justify-between items-center my-8">
          <h2 className="w-full lg:w-auto text-gray-700 font-bold text-xl lg:text-3xl text-center lg:text-left">
            Todos los cursos
          </h2>

          <div className="w-full lg:w-auto lg:flex justify-between items-center">
            <div className="py-4 flex items-center pr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <input
                onChange={searchFunction}
                onKeyDown={searchFunction}
                className="w-full lg:w-auto pl-1 block text-gray-700 placeholder:text-gray-700"
                type="text"
                placeholder="BUSCAR CURSOS"
              ></input>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                />
              </svg>
              <select
                name="dateFilter"
                id="dateFilter"
                className="w-full lg:w-auto pl-1 block text-gray-700"
                onChange={(event) => dateFilter(event.target.value)}
              >
                <option value="default">ORDENAR POR</option>
                <option value="old">Fecha: Más antiguo.</option>
                <option value="new">Fecha: Más reciente.</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          {isLoadingCourses ? (
            <div className="w-full flex justify-center">
              <TableLoading size="5x" />
            </div>
          ) : (
            courses &&
            courses.length > 0 && (
              <CoursesList
                courses={courses}
                handleAddCourse={(id) => handleAddCourse(id)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
