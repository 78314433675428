/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import _ from "lodash";
import Context from "../../../context/AdminUserContext";
import { adminUsersService } from "../../../services/api/admin/users";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../../styles/time-picker.css";
import "moment/locale/es";
import { es } from "date-fns/locale";
import moment from "moment";
import { notifications } from "../../../utils/notifications";

const EditUser = () => {
  const { currentAdminUser } = useContext(Context);
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const [type, setType] = useState();
  const [membershipExpiredAt, setMembershipExpiredAt] = useState();
  const [membershipCreatedAt, setMembershipCreatedAt] = useState();
  const [hasNoExpiredDate, setHasNoExpiredDate] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    type: "Admin",
    hasNoExpiredDate: false,
  });

  useEffect(() => {
    if (!_.isEmpty(user)) {
      let membreshipExpiredDate = new Date(user.membership_expired_at);
      let membreshipCreatedDate = user.membership_created_at
        ? new Date(user.membership_created_at)
        : null;
      setInitialValues({
        name: user.name || "",
        email: user.email || "",
        phone: user.phone || "",
        type: user.type,
        membership_renew_cost: user.membership_renew_cost || "",
        hasNoExpiredDate: moment(user.membership_expired_at).isAfter(
          "2035-01-01 00:00:00"
        )
          ? true
          : false,
      });
      if (user.membership_id) {
        setType(user.membership_id);
        setMembershipExpiredAt(membreshipExpiredDate);
        setMembershipCreatedAt(membreshipCreatedDate);
        if (user.orderId) {
          setOrderId(user.orderId);
        }
      }
    }
  }, [user]);

  const fetchUser = () => {
    adminUsersService
      .getUserById(params.id)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchUser();
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    let body = {};
    body = {
      membership_id: type,
      membership_expired_at:
        values.hasNoExpiredDate ||
        moment(membershipExpiredAt).isAfter("2035-01-01 00:00:00")
          ? moment
              .utc("2035-12-12 00:00:00")
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : moment
              .utc(membershipExpiredAt)
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      membership_renew_cost: values.membership_renew_cost,
    };
    console.log(body);
    await adminUsersService
      .updateUser(params.id, body)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/usuarios/");
      })
      .catch((err) => {});
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Editar usuario</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Nombre</p>
                  <Field
                    disabled="true"
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Correo</p>
                  <Field
                    disabled="true"
                    name="email"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Teléfono</p>
                  <Field
                    disabled="true"
                    name="phone"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Precio de renovación</p>
                  <Field
                    name="membership_renew_cost"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="membership_renew_cost"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Tipo de membresía</p>
                  <Field
                    as="select"
                    name="type"
                    value={type}
                    onChange={(event) => {
                      if (event.target.value === "0") {
                        setType(null);
                        setMembershipExpiredAt(null);
                      } else {
                        setType(event.target.value);
                      }

                      setFieldValue("type", event.target.value);
                    }}
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="0">Ninguna</option>
                    <option value="1">Basica Educativa</option>
                    <option value="2">Basica Dropshipping</option>
                    <option value="3">Plus Mensual</option>
                    <option value="4">Plus Trimestral</option>
                    <option value="7">Plus Semestral</option>
                    <option value="5">Plus Anual</option>
                    <option value="6">Premium</option>
                    <option value="9">Emprendedor</option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                {type && (
                  <div className="mb-9 text-3xl text-gray-550">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <div className="App">
                        <p>Fecha de expiración</p>
                        <div className="  flex flex-wrap md:flex-row space-y-4 md:space-y-0 my-2  items-center ">
                          <div className="pl-4 lg:pl-0 w-1/2 flex flex-row items-center space-x-2 xl:w-4/12">
                            <Field
                              name="hasNoExpiredDate"
                              className="t-20 w-5 h-5 my-auto bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                              type="checkbox"
                              checked={values.hasNoExpiredDate}
                            />
                            <p>Indefinida</p>
                          </div>
                          <div className="w-full md:w-1/2 xl:w-8/12">
                            {values.hasNoExpiredDate ? (
                              <div className="py-3 w-full bg-gray-400 h-16"></div>
                            ) : (
                              <DatePicker
                                className="timepicker"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                format="dd/MM/yyyy"
                                onChange={(value) => {
                                  setMembershipExpiredAt(value);
                                }}
                                value={membershipExpiredAt}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                )}
                {type && membershipCreatedAt && (
                  <div className="mb-9 text-3xl text-gray-550">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <div className="App">
                        <p>Fecha de pago</p>
                        <DatePicker
                          className="timepicker"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          disabled
                          format="dd/MM/yyyy"
                          value={membershipCreatedAt}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                )}
                {orderId && membershipCreatedAt && (
                  <div className="mb-9 text-3xl text-gray-550">
                    <p>Orden #{orderId}</p>

                    <button
                      onClick={() => navigate(`/admin/ordenes/bgp/${orderId}/`)}
                      className="w-full bg-primary-300 text-gray-700 placeholder:font-italitc border  py-3 px-4 focus:outline-none text-white hover:bg-primary-400 transition ease-in-out delay-30"
                      type="button"
                    >
                      Ver orden
                    </button>
                  </div>
                )}
              </div>

              {/* BUTTONS */}
              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                >
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditUser;
