import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import * as _ from "lodash";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import { adminUsersService } from "../../../services/api/admin/users";
import moment from "moment";
import {
  getColumnsForMemberships,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";

const Index = () => {
  const navigate = useNavigate();
  const { currentAdminUser } = useContext(Context);
  const firstRender = useRef(true);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formattedUsersData, setFormattedUsersData] = useState([]);
  const [querySearch, setQuerySearch] = useState("");
  const [selectedMemberships, setSelectedMemberships] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("today");

  const membershipOptions = [
    { id: "1", label: "Básica educativa" },
    { id: "2", label: "Mens. Dropshipping" },
    { id: "8", label: "Anual Dropshipping" },
    { id: "3", label: "Plus Mensual" },
    { id: "4", label: "Plus Trimestral" },
    { id: "7", label: "Plus Semestral" },
    { id: "5", label: "Plus Anual" },
    { id: "6", label: "Premium" },
    { id: "9", label: "Emprendedor" },
  ];
  const [totalMembers, setTotalMembers] = useState(0);

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());

  const formatTopUserData = () => {
    let data = [];

    users.forEach((user, idx) => {
      data.push({
        photoComponent: (
          <div className="w-10 h-10 mx-2">
            <img
              src={user.image}
              alt=""
              className="rounded-full w-full h-full object-cover"
              style={{ backgroundColor: "#D8D8D8" }}
            />
          </div>
        ),
        name: user.name,
        email: user.email,
        fund: user.fund,
        membership_expired_at: (
          <div className="text-primary-300 text-lg leading-none">
            {!user.membership_expired_at
              ? "Sin membresia"
              : moment(user.membership_expired_at).isAfter(
                  "2035-01-01 00:00:00"
                )
              ? "INDEFINIDA"
              : moment(user.membership_expired_at).format("DD/MM/YYYY")}
          </div>
        ),
        expiration_membership: user.membership_expired_at,
        actionComponent: (
          <div className="text-primary-200 hover:text-primary-300 text-lg leading-none">
            <button onClick={() => navigate(`/admin/usuarios/${user.id}`)}>
              Más Detalles
            </button>
          </div>
        ),
        coursesComponent: (
          <>
            {user.hasCourses ? (
              <div className="text-primary-200 hover:text-primary-300 text-lg leading-none">
                <button
                  onClick={() =>
                    navigate(`/admin/usuarios/estadisticas/${user.id}`)
                  }
                >
                  VISUALIZAR
                </button>
              </div>
            ) : (
              "NO DISPONIBLE"
            )}
          </>
        ),
        membership_id: user.membership_id,
      });
    });

    let dataDesc = _.orderBy(data, ["memberships_total"], ["desc"]);
    let dateDesc = _.orderBy(dataDesc, ["expiration_membership"], ["asc"]);

    setFormattedUsersData(dateDesc);
    setLoading(false);
  };

  const filteredData = formattedUsersData.filter((user) => {
    let matchesAllCriteria = true;

    if (querySearch !== "") {
      const matchesSearch =
        user.name.toLowerCase().includes(querySearch) ||
        user.email.toLowerCase().includes(querySearch);
      matchesAllCriteria = matchesAllCriteria && matchesSearch;
    }

    if (selectedMemberships.length > 0) {
      const matchesMemberships =
        user.membership_id !== null &&
        selectedMemberships.includes(user.membership_id.toString());
      matchesAllCriteria = matchesAllCriteria && matchesMemberships;
    }

    if (selectedFilter) {
      const tomorrow = moment().add(1, "day");
      switch (selectedFilter) {
        case "today":
          const startOfToday = moment().startOf("day");
          const endOfToday = moment().endOf("day");
          const isMembershipActiveToday =
            moment(user.expiration_membership).isBetween(
              startOfToday,
              endOfToday,
              null,
              "[]"
            ) && user.membership_id;
          matchesAllCriteria = matchesAllCriteria && isMembershipActiveToday;
          break;
        case "tomorrow":
          const isMembershipActiveTomorrow =
            moment(user.expiration_membership).isSame(tomorrow, "day") &&
            user.membership_id;
          matchesAllCriteria = matchesAllCriteria && isMembershipActiveTomorrow;
          break;
        case "other":
          const isMembershipActiveOther =
            moment(user.expiration_membership).isAfter(tomorrow.endOf("day")) &&
            user.membership_id;
          matchesAllCriteria = matchesAllCriteria && isMembershipActiveOther;
          break;
        case "expired":
          const isMembershipExpired =
            moment(user.expiration_membership).isBefore(moment()) &&
            !moment(user.expiration_membership).isSame(moment(), "day") &&
            user.membership_id;
          matchesAllCriteria = matchesAllCriteria && isMembershipExpired;
          break;
        default:
          break;
      }
    }

    return matchesAllCriteria;
  });
  if (selectedFilter === "expired") {
    filteredData.sort((a, b) =>
      moment(b.expiration_membership).diff(moment(a.expiration_membership))
    );
  }

  const handleMembershipChange = (event) => {
    const membershipId = event.target.value;
    if (membershipId && formattedUsersData) {
      if (event.target.checked) {
        setSelectedMemberships([...selectedMemberships, membershipId]);
      } else {
        setSelectedMemberships(
          selectedMemberships.filter((id) => id !== membershipId)
        );
      }
    }
  };

  const handleExpirationChange = (event) => {
    const option = event.target.value;
    setSelectedFilter(option);
  };

  useEffect(() => {
    setTotalMembers(filteredData.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  const fetchUsers = () => {
    adminUsersService
      .getUsersAll()
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchUsers();
  }, [currentAdminUser]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (_.isEmpty(users)) {
      setLoading(false);
      return;
    }
    formatTopUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-3xl md:text-4xl font-bold">Miembros</p>
        <div className="flex flex-col md:flex-row items-center gap-5 mt-5 md:mt-0">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              onChange={inputHandler}
            />
          </label>
        </div>
      </div>

      <>
        {/* <div className="flex flex-col md:flex-row items-center gap-8 mt-10 md:my-10"> */}
        {/* <div className="grid grid-cols-1 place-items-center h-[250px] overflow-y-scroll md:h-auto md:overflow-auto md:place-items-start md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 mt-10 md:my-10"> */}
        <div className="grid grid-cols-2 place-items-start place-content-center gap-4 md:gap-x-20 lg:gap-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mt-10 md:my-10">
          {membershipOptions.map((option) => (
            <label
              key={option.id}
              className="flex h-full items-center space-x-2 text-base md:text-lg"
            >
              <input
                type="checkbox"
                name="memberships"
                className="w-5 h-5"
                value={option.id}
                checked={selectedMemberships.includes(option.id)}
                onChange={handleMembershipChange}
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
        <div className="flex flex-wrap md:flex-row mt-5 mb-10 space-x-4">
          <div className="w-auto py-2 flex  flex-wrap md:flex-row space-x-2 justify-items-center text-center space-y-4 lg:space-y-0">
            <div className="my-auto w-full md:w-auto">Filtrar por :</div>
            <select
              onChange={handleExpirationChange}
              value={selectedFilter}
              name="select"
              className="w-full md:w-auto bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
            >
              <option value="today" selected>
                Vencimientos: Hoy
              </option>
              <option value="tomorrow"> Vencimientos: Mañana</option>
              <option value="other">Vencimientos próximos a vencer</option>
              <option value="expired">Vencidos</option>
              <option value="none">Todos los miembros</option>
            </select>
          </div>
        </div>
      </>

      <div>
        <p className="text-3xl md:text-4xl font-bold text-center">
          Total usuarios: <b className="text-primary-200">{totalMembers}</b>
        </p>
      </div>
      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForMemberships}
          data={filteredData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
    </div>
  );
};

export default Index;
